import {useState} from "react";
import React from "react";
import WelcomeComponent from "../components/welcome/WelcomeComponent";
import {PageType} from "../utils/PageType";
import EmailComponent from "../components/email/EmailComponent";
import {UserInfo} from "../dto/UserInfo";
import DestinationComponent from "../components/destination/DestinationComponent";
import RedeemComponent from "../components/redeem/RedeemComponent";

const AppPage: React.FC = () => {

    const [pageType, setPageType] = useState<PageType>(PageType.WELCOME);
    const [userInfo, setUserInfo] = useState<UserInfo>({
        phone: "",
        email: "",
        country: undefined
    })

    return (
        <>
            {   pageType === PageType.WELCOME ? <WelcomeComponent setPageType={setPageType}/> :
                pageType === PageType.EMAIL ? <EmailComponent setPageType={setPageType} userInfo={userInfo} setUserInfo={setUserInfo}/> :
                pageType === PageType.DESTINATION ? <DestinationComponent setPageType={setPageType} userInfo={userInfo} setUserInfo={setUserInfo}/> :
                pageType === PageType.REDEEMED ? <RedeemComponent setPageType={setPageType} userInfo={userInfo} setUserInfo={setUserInfo}/>
                    : <></>
            }
        </>
    );
}

export default AppPage;
