import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBUTanDOUYqcvpk0QZLncPB_Ov92aXsQxU",
    authDomain: "simly-shahid-campaign.firebaseapp.com",
    projectId: "simly-shahid-campaign",
    storageBucket: "simly-shahid-campaign.appspot.com",
    messagingSenderId: "666469212895",
    appId: "1:666469212895:web:3269962bdeb19c3ddd7f6a",
    measurementId: "G-B1WHJ5B54S"
};

export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
export const analytics = getAnalytics(app);