import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Lottie from "lottie-react";
import lockedAnimation from "../animations/lottie_locked_region.json";
import {REGION_LOCK_ISO} from "../utils/options";

const NotInRegion: React.FC = () => {
    const { t } = useTranslation();

    const [location, setLocation] = useState<string | undefined>(undefined);

    const fetchIPData = async () => {
        try {
            const response = await fetch('https://ipapi.co/json/', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if(!response.ok) {
                setLocation("ERR");
            }

            const jsonedResponse = await response.json();
            setLocation(jsonedResponse.country_code);
        } catch (error) {
            setLocation("ERR");
        }
    };

    useEffect(() => {
        fetchIPData();
    })

    return (
        <div className="h-screen w-full flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center text-center">
                <Lottie className={"w-[80%]"} animationData={lockedAnimation} loop={true}/>
                <h1 className="w-[90%] text-3xl font-extrabold text-gray-800">{t('regionLocked')}</h1>
                <h2 className="mt-5 w-[40%] text-xl font-bold text-gray-700">📍 Location: {location}</h2>
            </div>
        </div>
    );
};

export default NotInRegion;
