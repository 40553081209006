import {CAMPAIGN_ID, SIMLY_API_URL} from "../utils/options";
import {CampaignStatus} from "./dtos/CampaignStatus";
import {CountryWithPlansItem, DestinationsDTO, SimDTO} from "./dtos/GlobalDTOs";

export class CampaignService {

    static getCampaignStatus = async () => {
        const response = await fetch(`${SIMLY_API_URL}/campaigns/${CAMPAIGN_ID}/status`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        });

        if(response.ok) {
            const jsonedResponse = await response.json();
            return jsonedResponse.data as CampaignStatus;
        } else {
            throw new Error("An error occurred whilst fetching the campaign status.")
        }
    }

    static getCampaignCountries = async (search?: string) => {
        const response = await fetch(`${SIMLY_API_URL}/campaigns/${CAMPAIGN_ID}/countries${search ? `?search=${search}` : ``}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": navigator.language || navigator.languages[0]
            },
        });

        if(response.ok) {
            const jsonedResponse = await response.json();
            return jsonedResponse.data as DestinationsDTO;
        } else {
            throw new Error("An error occurred whilst fetching the campaign countries.")
        }
    }

    static getCampaignCountry = async (isoCode: string) => {
        const response = await fetch(`${SIMLY_API_URL}/campaigns/${CAMPAIGN_ID}/countries/${isoCode}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": navigator.language || navigator.languages[0]
            },
        });

        if(response.ok) {
            const jsonedResponse = await response.json();
            return jsonedResponse.data as CountryWithPlansItem;
        } else {
            throw new Error("An error occurred whilst fetching the campaign country.")
        }
    }

    static isPhoneRedeemed = async (phone: string) => {
        const response = await fetch(`${SIMLY_API_URL}/campaigns/${CAMPAIGN_ID}/phone/${phone}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if(response.ok) {
            const jsonedResponse = await response.json();
            return jsonedResponse.data as boolean;
        } else {
            throw new Error("An error occurred whilst fetching the status of the phone.")
        }
    }

    static isEmailRedeemed = async (email: string) => {
        const response = await fetch(`${SIMLY_API_URL}/campaigns/${CAMPAIGN_ID}/email/${email}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if(response.ok) {
            const jsonedResponse = await response.json();
            return jsonedResponse.data as boolean;
        } else {
            throw new Error("An error occurred whilst fetching the status of the email.")
        }
    }

    static redeemCampaign = async (email: string, planId: string, authorizationToken?: string) => {
        const response = await fetch(`${SIMLY_API_URL}/campaigns/${CAMPAIGN_ID}/redeem`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authorizationToken
            },
            body: JSON.stringify({
                email,
                planId
            })
        });

        if(response.ok) {
            const jsonedResponse = await response.json();
            return jsonedResponse.data as {
                sim: SimDTO,
                user: {email?: string, password?: string}
            };
        } else {
            throw new Error("An error occurred whilst redeeming the campaign.")
        }
    }
}
